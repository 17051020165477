Accordion = {

    container: {},

    init: function(){
        this.container = $('.row[data-component="accordion"]');
        this.container.find('.headline').unbind('click')
            .click(function(event) {

                if(!$(this).is('.expanded')){
                    Accordion.open($(this));
                } else {
                    Accordion.close($(this))
                }
            });

    },

    open: function(item){

        Accordion.close(this.container.find('.expanded'));

        // $(item).addClass('expanded');

        var content = $(item).next('.content');
        var height = content.outerHeight();

        content.css({display: 'block', height:0});
        content.animate({height: height}, 500, function() {
            $(this).css({height: ''});
            $(this).prev('.headline').addClass('expanded');
        });
    },

    close: function(item){

        var content = $(item).next('.content');
        content.animate({height: 0}, 500, function() {
            $(this).css({height: '', display:'none'});
            $(this).prev('.headline').removeClass('expanded');

        });

    }
}