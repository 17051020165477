$(document).ready(function($) {
	Search.init();
});

Search = {
	el:{},
	height:0,

	init: function(){
		this.el = $('.row.search');
		this.openBtn = $('.row.header .search.open');
		this.closeBtn = $('.row.header .button.close');
		this.searchBtn = $('.row.header .button.search');
		this.input = $('.row.header .seach-input');

		this.bind();
	},

	bind: function(){
		this.openBtn.off('click')
			.click(function(event) {
				event.preventDefault();
				Search.show();
			});

		this.closeBtn.off('click')
			.click(function(event){
				event.preventDefault();
				Search.hide();
			})

		this.searchBtn.off('click')
			.click(function(event){
				event.preventDefault();
				Search.search(Search.input.val());
			})
		this.input.keypress(function(e) {
		    if(e.which == 13) {
		      	Search.search(Search.input.val());
		    }
		});
	},

	show: function(){
		this.el.slideDown(300)
	},

	hide: function(){
		this.el.slideUp(300)
	},

	search: function(string){
		window.location = WEB_ROOT + "/vyhladavanie?s=" + string
	}
}