$(document).ready(function(){
    if(typeof Editor != "undefined") return;
    Menu.init();
    Router.init();
    App.init();
    Unpacker.init();
}); 

var resizeId;
$(window).resize(function() {

    clearTimeout(resizeId);
    resizeId = setTimeout(doneResizing, 300);
});
 
function doneResizing(){
    if(typeof Editor != "undefined") return;
    App.removeTransition();
    App.setRatio();
    Arrows.align();
    Router.parseUrl();
   
}

$(window).load(function(){
   
});

Interaction = {
    init: function(){

        $('body').bind('mousewheel', function(e) {
            
            if(App.isAnimating) return;
            App.isAnimating = true;
            setTimeout(function(){App.isAnimating = false; console.log('off')}, 500)

            if(e.originalEvent.wheelDelta / 120 > 0) {
                Arrows.goUp();
            } else {
                Arrows.goDown();
            }
        });
    },

    setBlockAnimation: function(){

    }
}

ScrollBar = {
    init: function(){

        if (App.isMobile) return;
        $(".slide > .content > .cell").not("[noscroll]").mCustomScrollbar();
        $(".reference .basic").mCustomScrollbar();
        $(".reference .advance").mCustomScrollbar();
        // $("nav.main").mCustomScrollbar();
    }
}

Router = {
    init: function(){
        History.Adapter.bind(window,'statechange',function(){ 
            var State = History.getState(); 
            Router.parseUrl();
        });

        Router.bindMenu();
    },

    bindMenu: function(){

        Menu.items.find('a').click(function(event) {
            event.preventDefault();
            
            if ($(this).closest('li').is('.hasChildren')) return;

            var url = $(this).attr('href');
            var title = $(this).attr('title');
            History.pushState({state:2}, title, url);

            Menu.items.removeClass('selected');
            $(this).closest('.item').addClass('selected');
            $(this).closest('.dropdown').closest('.item').addClass('selected');
        })
    },

    parseUrl: function(){
        var url = window.location.href;
        var string = url.replace(WEB_ROOT+"/", "");
        var array = string.split("/");
        var vertical = array[0];
        var horizontal = array[1];
        
        if(vertical == "") verical ="home";
        // if(vertical == "") verical ="home";

        Elevator.slide(vertical, horizontal);
        Header.align();
        Menu.align();
    }
}

App = {

    width:0,
    height:0,
    isMobile:false,
    orientation:"Landscape",
    isAnimating:false,

    init: function(){
        Header.init();
        Elevator.init();
        Slider.init();
        Slide.init();
        App.setRatio();
        Router.parseUrl();
        Arrows.build();
        ScrollBar.init();
        Interaction.init();
    },

    setRatio: function(){

        App.width = $(window).innerWidth();
        App.height = $(window).innerHeight();

        App.checkOrientation();
        App.checkIsMobile();
    },

    checkIsMobile: function(){

        if ($(window).innerWidth() < 769){

            App.fitToScreen();
            App.isMobile = true;

        } else {

            App.isMobile = false;
            var ratio = App.height / App.width;

            if (ratio > Slide.ratio)
                App.fitToWidth();
            else
                App.fitToHeight();
        }
    },

    checkOrientation: function(){
        
        if(App.height / App.width > 1){
            App.orientation = "Portrait";
            $('body').removeClass().addClass('portrait');
            return;
        }

        App.orientation = "Landscape";
        $('body').removeClass().addClass('landscape');
    },

    fitToWidth: function(){
        console.log('app.fitToWidth')
        var width = App.width - (App.width * (2 * Slide.gap));
        var height = width * Slide.ratio;
        Slide.setDimensions(width, height);
        Slider.setDimensions();
    },

    fitToHeight: function(){
         console.log('app.fitToHeight')
        var height = App.height - (App.height * (2 * Slide.gap));
        var width = height / Slide.ratio;
        Slide.setDimensions(width, height);
        Slider.setDimensions();
    },

    fitToScreen: function(){
        Slide.setDimensions(App.width - 10, App.height - 10);
        Slider.setDimensions();
    },

    removeTransition: function(){
        $('*').addClass('no-transition');
        setTimeout(function(){
            $('*').removeClass('no-transition');
        },1)
    }
}

Header = {
    elm: {},
    init: function(){
        Header.elm = $('header.topheader');
    },

    align: function(){

        return;

        var logoLeft = 15;
        var buttonLeft = 'auto';
        var buttonRight = 30;
       

        if(!App.isMobile){
            var logoLeft = parseInt(Slider.currentSlide.attr('number')) == 0 ? 15 : Slide.padding + 15;
            var buttonLeft = Slide.width + logoLeft - 15 - 20 - 30;
            var buttonRight = 'auto';
        } 
        
        Header.elm.find('.logo').css({left: logoLeft});
        Header.elm.find('.menu-btn').css({left: buttonLeft, right:buttonRight});
    }
}

Arrows = {

    build: function(){
        $('<a>', {'class':'arrow next icon-arrow'}).attr('dir','1').appendTo(Elevator.el);
        $('<a>', {'class':'arrow prev icon-arrow'}).attr('dir','-1').appendTo(Elevator.el);
        $('<a>', {'class':'arrow up icon-arrow'}).attr('dir','-1').appendTo(Elevator.el);
        $('<a>', {'class':'arrow down icon-arrow'}).attr('dir','1').appendTo(Elevator.el);
        Arrows.align();
        Arrows.bind();
        Arrows.relink();
    },

    align: function(){
        
        var top = Slide.height / 2;

        if(Elevator.currentSlider.attr('number') != 0){
             top = "50%";
        } 

        if(App.orientation == 'Portrait') {
            // top = 80 + (App.width * Slide.ratio) + 50;
        }
        
        Elevator.el.find('.next').css({top: top});
        Elevator.el.find('.prev').css({top: top});
        Elevator.hideArrows($('.slider.current'))
        Slider.hideArrows($('.slider.current .slide.current'))
    },

    bind: function(){

        Elevator.el.find('.arrow').click(function(event) {
            event.preventDefault();
            History.pushState({state:2}, $(this).attr('title'), $(this).attr('href'));
        });

    },

     relink: function(){
        this.relinkNext();
        this.relinkPrev();
        this.relinkUp();
        this.relinkDown();
    },

    relinkNext: function(){
        var nextSlide = $('.slider.current .slide.current').next('.slide');

        if (!nextSlide.length) 
            nextSlide = $('.slider.current').find('.slide:first-child').next('.slide');

        var url = WEB_ROOT + "/" + $('.slider.current').attr('url') + '/' + nextSlide.attr('url');
        var title =  nextSlide.attr('title');

        Elevator.el.find('.next').attr({href: url, title: title });
    },

    relinkPrev: function(){
        var prevSlide = $('.slider.current .slide.current').prev('.slide')
        var url = WEB_ROOT + "/" + $('.slider.current').attr('url') + '/' + prevSlide.attr('url');
        var title =  prevSlide.attr('title');

        Elevator.el.find('.prev').attr({href: url, title: title });
    },

    relinkUp: function(){
        var prevSlider = $('.slider.current').prev('.slider');
        var currslide = prevSlider.find('.slide.current');

        if(!currslide.length) 
            currslide = prevSlider.find('.slide:first-child');

        var url = WEB_ROOT + "/" + prevSlider.attr('url') + '/' + currslide.attr('url');
        var title =  currslide.attr('title');

        Elevator.el.find('.up').attr({href: url, title: title });
    },

    relinkDown: function(){
        var nextSlider = $('.slider.current').next('.slider');

        var currslide = nextSlider.find('.slide.current');

        if(!currslide.length) 
            currslide = nextSlider.find('.slide:first-child');

        if (!nextSlider.length) 
            nextSlider = $('.slider:first-child').next('.slider');

        var url = WEB_ROOT + "/" + nextSlider.attr('url') + '/' + currslide.attr('url');
        var title =  currslide.attr('title');

        Elevator.el.find('.down').attr({href: url, title: title });
    },

    goUp:function() {
        if(Elevator.el.find('.up').is('.hidden')) return;
        Elevator.el.find('.up').click();
    },

    goDown:function() {
        if(Elevator.el.find('.down').is('.hidden')) return;
        Elevator.el.find('.down').click();
    }
}

Elevator = {

    el:{},
    currentSlider:{},

    init: function(){
        this.el = $('.elevator');
    },

    slide: function(vertical, horizontal){

        var slider = Elevator.el.find('.slider[url="' + vertical + '"]');
        if (!slider.length) return;

        Slide.Video.pause($('.slider.current .slide.current'));

        Elevator.el.find('.slider').removeClass('current');
        slider.addClass('current');

        Elevator.hideArrows(slider);
        Elevator.currentSlider = slider;

        var shift = 0;
        if(!App.isMobile){
            shift = parseInt(slider.attr('number')) == 0 ? 0 : ((window.innerHeight - Slide.height) / 2) - 5;
        }

        var top = parseInt(slider.attr('number') * Slide.height) - shift;
        Elevator.el.children('.inner').css({transform: 'translateY(' + (-top) + 'px)'});

        Slider.slide(horizontal);
        Arrows.align();
    },

    hideArrows: function(slider){

        $('.arrow.up, .arrow.down').removeClass('hidden');

         var current = parseInt(slider.attr('number'))

        if (current == 0) {
            $('.arrow.up').addClass('hidden');
        }
        if (current == $('.slider').length - 1) {
            $('.arrow.down').addClass('hidden');
        }
    },
}


Slider = {
    el:{},
    currentSlide: {},

    init: function(){
        Slider.el = $('.slider')
    },

    slide: function(url){

        var slide = $('.slider.current').find('.slide[url="' + url + '"]');
        if (!slide.length) return;

        Slide.Video.pause($('.slider.current .slide.current'));

        $('.slider.current .slide').removeClass('current');
        slide.addClass('current');

        Slide.Video.play($('.slider.current .slide.current'));

        Slider.hideArrows(slide);
        Slider.currentSlide = slide;

        var shift = 0;
        if(!App.isMobile){
            shift = parseInt(slide.attr('number')) == 0 ? 0 : (window.innerWidth - Slide.width) / 2;
        }
        var left = parseInt(slide.attr('number') * Slide.width) - shift;
        $('.slider.current').find('.inner').css({transform: 'translateX(' + (-left) + 'px)'});

        Arrows.relink();

    },

    hideArrows: function(slide){
        
        $('.arrow.prev, .arrow.next').removeClass('hidden');

        var current = parseInt(slide.attr('number'))

        if (current == 0) {
            $('.arrow.prev').addClass('hidden');
        }
        if (current == $('.slider.current .slide').length - 1) {
            $('.arrow.next').addClass('hidden');
        }
    },

    setDimensions: function(){
        Slider.el.each(function(index, el) {
            var count = $(this).find('.slide').length
            $(this).attr('count', count);
            $(this).width((count * Slide.width) + 1)

        });
    },

}

Slide = {
    ratio: 675 / 1200,
    gap: 0.12,
    padding: 0,
    width:0,
    height:0,
    el: {},

    init: function(){
         Slide.el = $('.slide');
         Slide.Texts.init();
    },

    setDimensions: function(width, height){
        Slide.Texts.align();

        Slide.width = width;
        Slide.height = height;
        Slide.padding =  ((App.width * Slide.gap) / 2) - 5;
        Slide.el.css({width: width+'px', height: height+'px'});
        
    },

    Video: {

        play: function(item){

            if(item.find('video').attr('src') == undefined){
                var src = 'preview-direct/' + item.find('video').attr('data-src');
                item.find('video').attr('src', src);
            }
            item.find('video')[0].play();

        },

        pause: function(item){

            if(!item.length) return;
            item.find('video')[0].pause();
        }
    },

    Texts: {

        init: function(){
            this.MoreInfo.init();
            this.CloseButton.init();
        },

        align: function(){
            this['fit' + App.orientation]();
        },

        fitLandscape: function(){
            console.log('fitLandscape')
             $('.slide .cell').css({top: '', position: '', height: '', width:''});
        },

        fitPortrait: function(){
            console.log('fitPortrait')
            var top = 80 + (App.width * Slide.ratio) + 20;
            var height = App.height - top - 25
            $('.slide .cell').css({top: top, position: 'absolute', height: height, width:'calc(100% - 40px)'});
        },

        hide: function(){

        },

        show: function(){

        },

        MoreInfo: {
            init: function(){
                $('.slide .cell .button.more').click(function(event) {
                    
                    var reference = $(this).parent('.reference');
                            
                    if(reference.is('.continue')){
                        reference.removeClass('continue');
                        $(this).removeClass('continue')
                    } else {
                        reference.addClass('continue');
                         $(this).addClass('continue')
                    }
                });
            }
        },

        CloseButton: {
             init: function(){
                $('.slide .cell .button.close').click(function(event) {
                    
                    var reference = $(this).next('.reference');
                            
                    if(reference.is('.closed')){
                        reference.removeClass('closed');
                        $(this).removeClass('closed')
                    } else {
                        reference.addClass('closed');
                         $(this).addClass('closed')
                    }
                });
            }
        }
    }

}

Unpacker = {
    init: function(){
        if(typeof Editor != "undefined") return;
        this.el = $('.unpacker');
        this.headers = this.el.find('h2, h3, h4');

        this.headers.each(function(index, el) {
            $(this).addClass('title');
            var contents = $('<div class="contents" />').insertAfter($(this));
            $(this).nextUntil('h2, h3, h4').appendTo(contents);

        });

        this.packAll();
        this.bind();
    },

    bind: function(){
        this.headers.click(function(event) {

            Unpacker.packSeleted();

            if (!$(this).is('.selected')){
                $(this).next('.contents').slideDown(500);
                $(this).addClass('selected');
                return;
            }

           

        });
    },

    packSeleted: function(){
        this.el.find('.selected').next('.contents').slideUp(500, function(){
            $(this).prev().removeClass('selected');
        });
    },

    packAll: function(){
        this.el.find('.contents').slideUp(0)
    }
}
