$(document).ready(function() {
	Tabs.init();
});

$(window).resize(function(event) {
	Tabs.setHeight();
});


Tabs = {
	el:{},
	items:{},
	selected:{},

	init: function(){
		this.el = $('[data-component="tabs"]');

		if(!this.el.length) return;

		this.items = this.el.find('.tab a');
		this.bind();
		this.parseUrl();
	},

	bind: function(){
		this.items.off('click')
			.click(function(event) {
				event.preventDefault();

				Tabs.unselect();

				if (!$(this).is('.selected'))
					Tabs.select($(this));
					
			});
	},

	parseUrl:function(){
		if(window.location.hash) {
			var tab = this.el.find('[tab="' + window.location.hash.substring(1) + '"]');
			Tabs.select(tab);
		}
	},

	select: function(tab){
		tab.addClass('selected');
		Tabs.selected = tab;
		this.el.find('.tab-content.'+ tab.attr('tab')).addClass('selected');
		this.setHeight();
		this.scrollUp();
	},

	unselect: function(){

		if(!Tabs.selected.length) return;

		Tabs.selected.removeClass('selected');
		this.el.find('.tab-content.'+ Tabs.selected.attr('tab')).removeClass('selected');

	},

	setHeight: function(){

		if(!Tabs.selected.length) return;

		var height = this.el.find('.tab-content.'+ Tabs.selected.attr('tab')).outerHeight()

		this.el.find('.holder').animate({height:height},500);

	},

	scrollUp: function(){

		if(!Tabs.selected.length) return;

		var top = Tabs.selected.offset().top - 120;
		setTimeout(function(){
			$('body, html').animate({scrollTop:top}, 500)
		},200)
		
	}
}