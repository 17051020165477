Menu = {

    el: {},
    button:{},
    items:{},

    init: function(){
         this.el = $('.navigation');
         this.buttton = $('.menu-btn');
         this.items = $('nav ul li');
         this.bind();
    },

    bind: function(){
        this.buttton.off('click')
            .click(function(event) {
                event.preventDefault();

                if ($(this).is('.selected')){
                    Menu.close();
                    $(this).removeClass('selected');
                } else {
                    Menu.open();
                    $(this).addClass('selected');
                }
            });

        this.items
            // .mouseover(function(event) {
            //     $(this).find('.dropdown').addClass('hover');
            // })
            // .mouseout(function(event) {
            //     $(this).find('.dropdown').removeClass('hover')
            // })
            .click(function(event) {
                if ($(this).closest('li').is('.hasChildren')){
                    event.preventDefault();
                    if ($(this).find('.dropdown').hasClass('hover'))
                        $(this).find('.dropdown').removeClass('hover');
                    else
                        $(this).find('.dropdown').addClass('hover');
                } else {
                    Menu.close();
                    Menu.buttton.removeClass('selected');
                    $('.hasChildren .dropdown').removeClass('hover');
                }
            });
    },

    open: function(){
        Menu.el.addClass('show').removeClass('hide');
        Menu.align();
    },

    close: function(){
       Menu.el.addClass('hide').removeClass('show');
    },

    align: function(){

        return;

        var shift = 0

        if(!App.isMobile){
            shift = parseInt(Slider.currentSlide.attr('number')) == 0 ? 0 : Slide.padding;
        }
        var left = Slide.width + shift;

        $('nav.main').css({left: left - $('nav.main').outerWidth()});
    }

}